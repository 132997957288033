<div>
  <nb-form-field>
    <button
      nbPrefix
      nbButton
      ghost
      *ngIf="equipment"
      type="button"
      (click)="gotoEquipment()"
      class="customerSearch"
    >
      <nb-icon icon="arrow-circle-right-outline"> </nb-icon>
    </button>
    <input
      nbInput
      fullWidth
      placeholder="Select Equipment"
      class="customerInput"
      fieldSize="small"
      [value]="equipmentNum"
      [nbPopover]="list"
      [nbPopoverPlacement]="'bottom'"
      readonly
    />
    <button
      nbSuffix
      nbButton
      ghost
      *ngIf="equipmentNum"
      type="button"
      (click)="selected(null)"
    >
      <nb-icon icon="close-circle-outline"> </nb-icon>
    </button>
  </nb-form-field>
</div>

<ng-template #list>
  <nb-card class="margin-bottom-0">
    <next-object-list
      [object]="'Equipment'"
      style="width: 800px"
      (onDoubleClick)="selected($event, list)"
      [filters]="filters"
      [hideToolBar]="true"
      [hideQueues]="true"
      [height]="400"
    >
    </next-object-list>
  </nb-card>
</ng-template>
