import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { Mutation_GenerateChecklistPdf } from 'packages/stack-web/src/app/@core/graphql/mutations/checklist/generateChecklistPdf';
import { Mutation_UpdateChecklistTemplate } from 'packages/stack-web/src/app/@core/graphql/mutations/checklist/updateChecklistTemplate.mutation';
import { Query_GetChecklistTemplate } from 'packages/stack-web/src/app/@core/graphql/queries/checklist/getChecklistTemplate.query';
@Injectable({
  providedIn: 'root',
})
export class ChecklistApiService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  generateChecklistPdf(id: string) {
    return this.apollo.mutate({
      mutation: Mutation_GenerateChecklistPdf,
      variables: {
        id,
      },
    });
  }

  updateChecklistTemplate(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateChecklistTemplate,
      variables: {
        dto,
      },
    });
  }

  getChecklistTemplate(id: string) {
    return this.apollo.query({
      query: Query_GetChecklistTemplate,
      variables: {
        id,
      },
    });
  }
}
