@if (workOrderId) {
  <next-object-list
    id="checklistList"
    object="Checklist"
    [selectFirstRow]="true"
    (buttonAction)="buttonAction($event)"
    (onSelect)="selectChecklist($event)"
    [hideToolBar]="false"
    [filters]="[
      {
        column: 'workOrderId',
        value: workOrderId,
      },
    ]"
    height="200"
  ></next-object-list>
}

<br />
@if (this.checklist?.id) {
  <nb-tabset (changeTab)="attachmentTabClicked($event)">
    <nb-tab tabTitle="Checklist">
      <div class="row">
        <div class="col">
          @for (group of groupEntries; track group.name) {
            <div class="d-flex align-items-center">
              <h5 class="mb-2 mt-3">
                {{ group.name }}
              </h5>
              @if (group.name !== 'Detail') {
                <!-- <nb-badge [status]="getIconColor(group.name)" class="ml-2">
              {{ group?.items?.length }}
            </nb-badge> -->
              }
            </div>
            <nb-card>
              <nb-list>
                @for (item of group.items; track item.id) {
                  <nb-list-item
                    class="d-flex w-100"
                    style="flex-direction: column"
                  >
                    <div class="d-flex">
                      @if (group.name !== 'Detail') {
                        <nb-icon
                          style="min-width: 30px"
                          [icon]="getIconName(item.status)"
                          [status]="getIconColor(item.status)"
                          class="mr-3"
                        >
                        </nb-icon>
                      }
                      <div class="flex-grow-1">
                        <strong>{{ item.label }}</strong>

                        @if (isArray(item.value)) {
                          @switch (item.type) {
                            @case ('lines') {
                              <br />
                              @for (
                                gl of item.value;
                                track $index + ':' + gl?.id;
                                let isLast = $last
                              ) {
                                <div
                                  class="d-flex mt-2 pb-1 pt-1"
                                  style="border-bottom: 1px solid grey"
                                >
                                  @for (
                                    item of gl | keyvalue: valueOptionsOrder;
                                    track $index + ':' + item.key;
                                    let isLast = $last
                                  ) {
                                    <div class="col" style="padding-right: 0px">
                                      <u>{{ item.key }}</u>
                                      <p>
                                        <b>{{ item.value?.trim() }}</b>
                                      </p>
                                    </div>
                                  }
                                </div>
                              }
                            }
                            @default {
                              @for (value of item.value; track value.option) {
                                @if (group.name !== 'Passed') {
                                  <p class="labelAction">
                                    <span>&bull; {{ value.option }}</span>
                                    <!-- <span style="padding-left: 10px; margin-bottom: 3px"> -->
                                    @if (value?.actions?.length > 0) {
                                      @for (
                                        action of value.actions;
                                        track action
                                      ) {
                                        <span class="chip">{{ action }}</span>
                                      }
                                    }
                                  </p>
                                }
                                <!-- </span>x -->
                              }
                            }
                          }
                        } @else if (group.name !== 'Passed') {
                          <p>{{ item.value }}</p>
                        }
                        @if (item.notes) {
                          <p class="notes">
                            <strong>Notes: {{ item.notes }}</strong>
                          </p>
                        }
                      </div>
                    </div>
                    @if (lineAttachments(item?.id)?.length > 0) {
                      <div class="d-flex mt-2 checklist-container">
                        <span style="width: 40px"></span>
                        @for (
                          attachment of lineAttachments(item?.id);
                          track attachment.id
                        ) {
                          @if (attachment.fileName.endsWith('mp4')) {
                            <i
                              (click)="displayImage(attachment)"
                              style="
                                font-size: 3rem;
                                margin-left: 5px;
                                color: lightcoral;
                              "
                              class="fa-regular fa-file-video"
                            ></i>
                          } @else {
                            <img
                              (click)="
                                displayImage(
                                  attachment,
                                  lineAttachments(item?.id)
                                )
                              "
                              class="checklist-image"
                              style="
                                max-height: 140px;
                                border-radius: 5px;
                                margin-left: 5px;
                                cursor: pointer;
                              "
                              [src]="attachment?.signedURL"
                              [attr.data-pswp-src]="attachment?.signedURL"
                            />
                          }
                        }
                      </div>
                    }
                  </nb-list-item>
                }
              </nb-list>
            </nb-card>
          }
        </div>
        <!-- <div [hidden]="true" class="col mt-3">
          <h5>Attachments</h5>
          <next-attachment
            #attachmentComponent
            [disableAdd]="true"
            object="Checklist"
            [refId]="checklist?.id"
            (attachments)="attachments = $event"
          ></next-attachment>
        </div> -->
      </div>
    </nb-tab>
    <nb-tab tabTitle="Attachments">
      <next-attachment
        #attachmentComponent
        [object]="'Checklist'"
        [refId]="checklist?.id"
        [disableAdd]="true"
        (attachments)="attachments = $event"
      ></next-attachment>
    </nb-tab>
  </nb-tabset>
}
