import { gql } from 'apollo-angular';

export const QUERY_GETEQUIPMENTBYID = gql`
  query ($id: String!) {
    getEquipmentById(id: $id) {
      equipmentNum
      id
      serial
      make
      model
      notes
      fleetType
      fleetCode
      externalId
      description
      customerEquipmentNum
      issueReported
      issueNotes
      station
      equipmentOptions {
        equipmentModelOption
        description
        group
        qty
        salePrice
        costPrice
        vendorItemNum
      }
      customer {
        customerNum
        name
        id
        address1
        address2
        city
        state
        zipcode
        lat
        lng
        country
        billTo {
          id
          name
          customerNum
        }
      }
    }
  }
`;
