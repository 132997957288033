import { gql } from 'apollo-angular';

export const QUERY_GetEquipmentTransfers = gql`
  query ($search: DtoPaginatedFilter!) {
    getEquipmentTransfers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          transferNum
          externalId
          externalVersionId
          fromType
          fromCode
          fromName
          fromAddress1
          fromAddress2
          fromCity
          fromState
          fromZipcode
          fromCountry
          toType
          toCode
          toName
          toAddress1
          toAddress2
          toCity
          toState
          toZipcode
          toCountry
          postingDate
          shipmentDate
          receiptDate
          status
          rentalDirection
          sourceDocumentType
          sourceDocumentNum
          sourceDocumentLineNum
          transportationType
          createdAt
          updatedAt
          createdBy
          updatedBy
        }
      }
    }
  }
`;
