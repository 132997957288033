import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbOptionComponent,
} from '@nebular/theme';
import { CustomerPopoverComponent } from '../../customers/popover/customer-popover.component';
import { WindowService } from 'packages/stack-web/src/app/@core/services/window.service';
import { EquipmentDetailComponent } from 'packages/stack-web/src/app/pages/equipment/detail/equipmentdetail.component';

@Component({
  selector: 'next-equipmentinput',
  templateUrl: './equipmentinput.component.html',
  styleUrls: ['./equipmentinput.component.scss'],
})
export class EquipmentInputComponent implements OnInit {
  _equipment: any;
  _customer: any = {};
  isLoading: boolean;
  equipmentNum: string = '';
  popover = CustomerPopoverComponent;

  @ViewChild('autooption') autooption: NbOptionComponent;
  @ViewChild(NbAutocompleteDirective)
  autocompleteDirective: NbAutocompleteDirective<string> = null;

  @Output() onEquipmentChange = new EventEmitter();
  @Input() set equipment(equipment: any) {
    this._equipment = equipment;
    this.updateLabel();
  }
  get equipment() {
    return this._equipment;
  }

  @Input() set customer(customer: any) {
    this._customer = customer;
  }
  get customer() {
    return this._customer;
  }

  updateLabel() {
    this.equipmentNum = this.equipment?.equipmentNum || '';
  }

  constructor(private windowService: WindowService) {}

  ngOnInit(): void {}

  inputClick() {
    this.autooption.value = undefined;
  }

  gotoEquipment() {
    this.windowService.openWindow(EquipmentDetailComponent, {
      context: {
        id: this.equipment?.id,
      },
      objectId: this.equipment?.id,
      options: {
        title: 'Equipment',
      },
    });
  }

  selected(equipment, template) {
    this.equipment = equipment;
    this.updateLabel();
    this.onEquipmentChange.emit(equipment);
    console.log(equipment);
    console.log(template);
    template.hide();
    if (!equipment) return;

    this.autooption.onClick({
      preventDefault: () => {
        return;
      },
    });
  }
}
