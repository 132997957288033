import { gql } from 'apollo-angular';

export const QUERY_GETCHECKLISTS = gql`
  query ($search: DtoPaginatedFilter!) {
    getAllChecklistsByWorkOrderId(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          createdBy {
            id
            fullName
          }
          checkListTemplateId
          name
          summaryText
          status
          json
          workOrder {
            id
            workOrderNum
            subject
            status
          }
          equipment {
            id
            category
            group
            equipmentNum
            description
            serial
            make
            model
            fleetCode
          }
          equipmentMake
          equipmentModel
          equipmentSerial
        }
      }
    }
  }
`;
