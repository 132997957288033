import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NbWindowService, NbWindowState } from '@nebular/theme';
import { Subject, takeUntil } from 'rxjs';
interface WindowParams {
  objectId?: any;
  context?: any;
  options?: {
    title?: string;
    windowClass?: string;
    buttons?: {
      fullScreen?: boolean;
      maximize?: boolean;
      minimize?: boolean;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  private openWindows = [];
  private destroy$ = new Map<any, Subject<void>>();
  constructor(private windowService: NbWindowService) {}

  openWindow(component: any, params: WindowParams) {
    const openWindow = this.findWindow(params.objectId);
    if (openWindow) {
      openWindow.windowRef.close();
      this.openWindows = this.openWindows.filter(
        (window) => window.objectId !== params.objectId,
      );
      this.destroy$.get(params.objectId)?.next();
      this.destroy$.delete(params.objectId);
    }
    const windowRef = this.windowService.open(component, {
      title: params?.options?.title || 'Loading...',
      closeOnBackdropClick: false,
      closeOnEsc: false,
      buttons: params?.options?.buttons
        ? params?.options.buttons
        : { fullScreen: true, maximize: false },
      windowClass: params?.options?.windowClass || 'detail-window',
      context: params.context,
    });

    this.openWindows.push({ objectId: params.objectId, windowRef: windowRef });

    const destroy = new Subject<void>();
    this.destroy$.set(params.objectId, destroy);

    windowRef.onClose.pipe(takeUntil(destroy)).subscribe(() => {
      this.openWindows = this.openWindows.filter(
        (window) => window.objectId !== params.objectId,
      );
      this.destroy$.get(params.objectId).next();
      this.destroy$.delete(params.objectId);
    });

    windowRef.stateChange.pipe(takeUntil(destroy)).subscribe((state) => {
      //when minimized make minimize false and maximize true
      if (state.newState === NbWindowState.MINIMIZED) {
        windowRef.config.buttons.minimize = false;
        windowRef.config.buttons.fullScreen = true;
      }

      //when maximized make minimize true and maximize false
      if (state.newState === NbWindowState.FULL_SCREEN) {
        windowRef.config.buttons.minimize = true;
        windowRef.config.buttons.fullScreen = false;
      }
    });

    windowRef.componentInstance.windowRef = windowRef;
  }

  private findWindow(objectId: string) {
    return this.openWindows.find((window) => window.objectId === objectId);
  }
}
