import { gql } from 'apollo-angular';

export const QUERY_GetEquipmentTransferLines = gql`
  query ($search: DtoPaginatedFilter!) {
    getEquipmentTransferLines(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          lineNum
          externalVersionId
          description
          description2
          uom
          qtyPerUom
          quantity
          qtyToDeliver
          qtyDelivered
          qtyToReceive
          qtyReceived
          qtyInTransit
          status
          shipmentDate
          receiptDate
          fromCode
          toCode
          fromBinCode
          toBinCode
          fixedBin
          toFixedBin
          serialNum
          fleetCode
          equipmentCategory
          equipmentGroup
          equipmentModel
          completelyShipped
          completelyReceived
          linkedDocType
          linkedDocNum
          linkedLineNum
          linkedDetailLineNum
          createdAt
          updatedAt
          wrongObjectId
          damageReported
          stationNum
          issueReported
          issueNotes
          wrongObject {
            id
            equipmentNum
            make
            model
            serial
            fleetCode
            customerEquipmentNum
            status
          }
          equipment {
            id
            equipmentNum
            make
            model
            serial
            fleetCode
            customerEquipmentNum
            status
          }
          equipmentTransfer {
            id
            transferNum
            fromType
            fromCode
            fromName
            toType
            toCode
            toName
            status
            postingDate
            shipmentDate
            receiptDate
            rentalDirection
            sourceDocumentType
            sourceDocumentNum
            transportationType
          }
        }
      }
    }
  }
`;
