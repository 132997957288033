import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MUTATION_ADDEQUIPMENT } from '../graphql/mutations/equipment/addequipment.mutation';
import { MUTATION_UPDATEEQUIPMENT } from '../graphql/mutations/equipment/updateequipment.mutation';
import { QUERY_GETALLEQUIPMENT } from '../graphql/queries/equipment/getallequipment.query';
import { QUERY_GetEquipment } from '../graphql/queries/equipment/getequipment.query';
import { QUERY_GETEQUIPMENTBYID } from '../graphql/queries/equipment/getequipmentbyid.query';
import { Query_GetEquipmentFieldGroupByCount } from '../graphql/queries/equipment/getEquipmentFieldGroupByCount.query';
import { QUERY_GetEquipmentInventory } from '../graphql/queries/equipment/getEquipmentInventory.query';
import { Query_GetEquipmentModels } from '../graphql/queries/equipment/getEquipmentModels.query';
import { IPaginatedFilter } from '../interfaces/IPaginatedFilter';

import { QUERY_GetEquipmentTransferById } from '../graphql/queries/equipment/getequipmenttransferbyid.query';
import { map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(private apollo: Apollo) {}

  addEquipment(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_ADDEQUIPMENT,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETALLEQUIPMENT,
        },
      ],
    });
  }

  getEquipmentTransfer(id: string) {
    return this.apollo
      .watchQuery({
        query: QUERY_GetEquipmentTransferById,
        fetchPolicy: 'cache-and-network',
        variables: { id },
      })
      .valueChanges.pipe(map((res: any) => res.data.getEquipmentTransfer));
  }

  updateEquipment(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_UPDATEEQUIPMENT,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETEQUIPMENTBYID,
          variables: { id: data.id },
        },
      ],
    });
  }

  getAllEquipment() {
    return this.apollo.watchQuery({
      query: QUERY_GETALLEQUIPMENT,
    }).valueChanges;
  }

  getEquipmentById(id: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETEQUIPMENTBYID,
      variables: { id: id },
    }).valueChanges;
  }

  getEquipment(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: QUERY_GetEquipment,
      variables: { search: search },
    }).valueChanges;
  }

  getEquipmentInventory(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: QUERY_GetEquipmentInventory,
      variables: { search: search },
    }).valueChanges;
  }

  getEquipmentModels(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetEquipmentModels,
      variables: { search: search },
    }).valueChanges;
  }

  getEquipmentModelsQuery(search: IPaginatedFilter) {
    return this.apollo.query({
      query: Query_GetEquipmentModels,
      variables: { search: search },
    });
  }

  getEquipmentFieldGroupByCount(fields: string[], search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetEquipmentFieldGroupByCount,
      variables: { search: search, fields: fields },
    }).valueChanges;
  }

  // getCustomerTypes() {
  //   return this.apollo.watchQuery({
  //     query: QUERY_GETCUSTOMERTYPES,
  //   }).valueChanges;
  // }

  // getCustomersBillToSearch(search: string) {
  //   return this.apollo.watchQuery({
  //     query: QUERY_GETCUSTOMERSBILLTOSEARCH,
  //     variables: { search },
  //   }).valueChanges;
  // }
}
