import { CommonModule, KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';
import {
  NbBadgeModule,
  NbCardModule,
  NbIconModule,
  NbTabsetModule,
} from '@nebular/theme';
import { AttachmentsModule } from '../../../attachments/attachments.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AttachmentComponent } from '../../../attachments/components/attachment/attachment.component';
import { WindowService } from 'packages/stack-web/src/app/@core/services/window.service';
import { AttachmentImageComponent } from '../../../attachments/components/attachment-image/attachment-image.component';
import { ChecklistApiService } from '../services/checklistApiService.service';
import { SubSink } from 'subsink';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DynamicListViewModule,
    NbCardModule,
    NbIconModule,
    NbBadgeModule,
    NbTabsetModule,
    AttachmentsModule,
    NzAvatarModule,
  ],
  selector: 'next-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss'],
})
export class ChecklistDetailComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  @Input() workOrderId: string;
  @Input() equipmentId: string;
  @ViewChild('attachmentComponent') attachmentComponent: AttachmentComponent;
  @Input() set checklist(value) {
    this._checklist = value;
    if (this.checklist) {
      this.initChecklist();
    }
  }
  get checklist() {
    return this._checklist;
  }
  private _checklist: any;
  groups: any;
  groupEntries: any = [];
  attachments;
  private lineMap: Map<string, any> = new Map();
  private lightbox: PhotoSwipeLightbox | null = null;
  private pswp: PhotoSwipe | null = null;

  constructor(
    private windowService: WindowService,
    private checklistService: ChecklistApiService,
  ) {}

  ngOnInit(): void {
    this.initPhotoSwipe();
  }

  private initPhotoSwipe() {
    this.lightbox = new PhotoSwipeLightbox({
      gallery: '.checklist-container',
      children: '.checklist-image',
      pswpModule: PhotoSwipe,
    });
    this.lightbox.init();
  }

  displayImage(attachment: any) {
    this.attachmentComponent.displayImage(attachment);
    return;
    // if (attachment.fileName.endsWith('mp4')) {
    //   // Handle video with existing logic
    //   this.attachmentComponent.openDialog(
    //     this.attachmentComponent.zoomDialog,
    //     attachment,
    //   );
    //   return;
    // }

    // // Filter out only image attachments from the current group
    // const imageAttachments = this.attachments.filter(
    //   (a: any) => !a.fileName.endsWith('mp4'),
    // );

    // // Create array of images for PhotoSwipe
    // const slides = imageAttachments.map((att: any) => ({
    //   src: att.signedURL,
    // }));

    // // Find the index of the clicked image
    // const startIndex = imageAttachments.findIndex(
    //   (a: any) => a.id === attachment.id,
    // );

    // // Initialize and open PhotoSwipe
    // const options = {
    //   dataSource: slides,
    //   index: startIndex,
    //   // Optional PhotoSwipe options
    //   closeOnVerticalDrag: true,
    //   bgOpacity: 0.9,
    // };

    // this.pswp = new PhotoSwipe(options);
    // this.pswp.init();
  }

  // displayImage(attachment) {
  //   this.attachmentComponent.openDialog(
  //     this.attachmentComponent.zoomDialog,
  //     attachment,
  //   );
  // }

  ngOnDestroy() {
    if (this.pswp) {
      this.pswp.destroy();
      this.pswp = null;
    }
    this.sub.unsubscribe();
  }

  selectChecklist(checklist) {
    if (!checklist) {
      this.checklist = null;
      return;
    }
    //if checklist is array then get first

    this.checklist = checklist[0];
    this.initChecklist();
  }

  initChecklist() {
    this.lineMap = new Map();
    this.groupEntries = [];
    this.buildLineMap(this.checklist.json);
    this.groupChecklistItems();
  }

  attachmentTabClicked(e) {
    if (e.tabTitle === 'Attachments') {
      this.attachmentComponent.reloadMas();
    }
  }

  groupChecklistItems() {
    this.groups = {};
    this.lineMap?.forEach((lineObject: any, key: string) => {
      let status = lineObject.status || 'Not Completed';
      if (lineObject.informational || lineObject.type === 'input') {
        status = 'Detail';
      }
      if (!this.groups[status]) {
        this.groups[status] = [];
      }
      if (lineObject.type === 'multimeter') {
        const lines = lineObject.value.map((line: any) => {
          return {
            ...line,
            notes: line.note,
            label: 'Cell ' + line.cellId,
            id: 'cell-' + line.cellId,
            value: `${line.voltage || 0.0}v / ${line.gravity || 0.0}`,
          };
        });
        this.groups[status].push(...lines);
      } else {
        this.groups[status].push(lineObject);
      }
    });

    this.groupEntries = Object.entries(this.groups)
      .map(([name, items]) => ({ name, items }))
      .sort(
        (a, b) => this.getGroupPriority(a.name) - this.getGroupPriority(b.name),
      );
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  lineAttachments(lineId: string) {
    return this.attachments?.filter(
      (attachment: any) => attachment?.refId2 === lineId,
    );
  }
  getIconName(status: string): string {
    switch (status) {
      case 'Passed':
        return 'checkmark-circle';
      case 'Detail':
        return '';
      default:
        return 'alert-circle';
    }
  }

  valueOptionsOrder = (
    a: KeyValue<string, any>,
    b: KeyValue<string, any>,
  ): number => {
    let options = [];
    for (const [key, line] of this.lineMap) {
      if (line?.type === 'lines') {
        options = line?.options;
      }
    }
    const optionIndexA = options.findIndex((opt: any) => opt.label === a.key);
    const optionIndexB = options.findIndex((opt: any) => opt.label === b.key);
    return optionIndexA - optionIndexB;
  };

  getIconColor(status: string): string {
    switch (status) {
      case 'Passed':
        return 'success';
      case 'Detail':
        return 'medium';
      default:
        return 'danger';
    }
  }

  getGroupPriority(groupName: string): number {
    const priorities = {
      Detail: 1,
      Passed: 10,
      'Not Completed': 20,
    };
    return priorities[groupName as keyof typeof priorities] ?? 3;
  }

  getBadgeColor(groupName: string): string {
    switch (groupName) {
      case 'Passed':
        return 'success';
      case 'Detail':
        return 'medium';
      default:
        return 'danger';
    }
  }
  buttonAction(action) {
    console.log(action.action);

    switch (action.action) {
      case 'generatePdf':
        this.generatePdf();
        break;
    }
  }

  generatePdf() {
    this.sub.sink = this.checklistService
      .generateChecklistPdf(this.checklist.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  buildLineMap(obj: any) {
    obj?.pages?.forEach((page: any) => {
      page?.groups?.forEach((group: any) => {
        group?.lines?.forEach((line: any) => {
          this.lineMap?.set(line.id, line);
        });
      });
    });
  }
}
