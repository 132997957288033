import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'next-attachment-image',
  templateUrl: './attachment-image.component.html',
  styleUrls: ['./attachment-image.component.scss'],
})
export class AttachmentImageComponent {
  @Input() set attachment(value) {
    this._attachment = value;
    this._attachment.signedURL = this._attachment?.signedURL?.trim();
    this.msURL = this.getOfficeSafeUrl(this?.attachment?.signedURL);
  }
  get attachment() {
    return this._attachment;
  }

  @Input() width = 480;
  @Input() height = 480;
  @Input() autoPlay = false;
  @Output() showImage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  retryCount = 0;
  maxRetries = 5;
  imageLoadFailed = false;
  showViewer = true;
  msURL: SafeUrl;
  _attachment;
  loading = true;

  constructor(private sanitizer: DomSanitizer) {}

  isVideo(fileName: string): boolean {
    const videoExtensions = ['mp4', 'mov', 'avi', 'flv', 'wmv', 'mkv'];
    const extension = this.getFileExtension(fileName);
    return videoExtensions.includes(extension.toLowerCase());
  }

  displayImage() {
    this.showImage.emit(this.attachment);
  }

  isOfficeDocument(fileName: string): boolean {
    const officeExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const extension = this.getFileExtension(fileName);
    return officeExtensions.includes(extension.toLowerCase());
  }

  getOfficeSafeUrl(url: string): SafeUrl {
    const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(officeViewerUrl);
  }

  imageDidLoad() {
    this.onImageLoaded.emit(true);
    this.retryCount = 0;
    this.loading = false;
  }

  getFileExtension(fileName: string): string {
    const parts = fileName?.split('.') || [];
    return parts.length > 1 ? parts.pop() : '';
  }

  handleError(event, attachment) {
    if (attachment?.retryCount === undefined) {
      attachment.retryCount = 0;
    }

    if (event && event.status === 403 && attachment?.retryCount < 4) {
      if (!attachment.oldUrl) {
        attachment.loadingError = true;
        attachment.oldUrl = attachment.signedURL;
        attachment.signedURL = null;
        attachment.retryCount = 0;
      }
      setTimeout(async () => {
        const response = await fetch(attachment.oldUrl);
        if (response.status === 200) {
          attachment.signedURL = attachment.oldUrl;
          attachment.loadingError = false;
          return;
        }
        attachment.retryCount++;
        this.handleError(response, attachment);
      }, 5000);
    }
  }
}
