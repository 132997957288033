import { gql } from 'apollo-angular';

export const Query_GetChecklistTemplate = gql`
  query ($id: String!) {
    getChecklistTemplate(id: $id) {
      id
      createdAt
      updatedAt
      name
      json
      name
      description
      requiresEquipment
      category
      group
      hidden
    }
  }
`;
