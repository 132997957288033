import { gql } from 'apollo-angular';

export const QUERY_GetEquipmentTransferById = gql`
  query ($id: String!) {
    getEquipmentTransfer(id: $id) {
      id
      transferNum
      externalId
      externalVersionId
      fromType
      fromCode
      fromName
      fromAddress1
      fromAddress2
      fromCity
      fromState
      fromZipcode
      fromCountry
      toType
      toCode
      toName
      toAddress1
      toAddress2
      toCity
      toState
      toZipcode
      toCountry
      postingDate
      shipmentDate
      receiptDate
      status
      rentalDirection
      sourceDocumentType
      sourceDocumentNum
      sourceDocumentLineNum
      transportationType
      status
      createdAt
      updatedAt
      createdBy
      updatedBy
      lines {
        id
        status
        qtyDelivered
        qtyToDeliver
        issueReported
        issueNotes
        equipment {
          id
          description
          equipmentNum
          serial
          make
          model
          fleetCode
          status
          category
          group
          fleetType
          equipmentGroupRef {
            id
            code
            primary
            m1
            m2
            m3
            m4
          }
        }
      }
    }
  }
`;
