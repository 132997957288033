import { gql } from 'apollo-angular';

export const Mutation_UpdateChecklistTemplate = gql`
  mutation upsertChecklistTemplate($dto: DtoUpsertChecklistTemplate!) {
    upsertChecklistTemplate(dto: $dto) {
      id
      createdAt
      updatedAt
      name
      json
      name
      description
      requiresEquipment
      category
      group
      hidden
    }
  }
`;
