import { gql } from 'apollo-angular';

export const Query_GetEquipmentFieldGroupByCount = gql`
  query ($fields: [String!]!, $search: DtoPaginatedFilter!) {
    getEquipmentFieldGroupByCount(fields: $fields, search: $search) {
      field
      values {
        value
        count
      }
    }
  }
`;
