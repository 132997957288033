import { gql } from 'apollo-angular';

export const QUERY_GETALLEQUIPMENT = gql`
  query {
    getAllEquipment {
      id
      equipmentNum
      serial
      make
      model
      customerEquipmentNum
      customer {
        id
        name
        customerNum
        address1
        address2
        city
        state
        zipcode
        country
        lat
        lng
        billTo {
          id
          name
          customerNum
        }
      }
    }
  }
`;
