import { gql } from 'apollo-angular';

export const Fragment_WorkOrder = gql`
  fragment WorkOrderFragment on WorkOrder {
    id
    type
    category
    status
    createdAt
    updatedAt
    workOrderNum
    category
    serial
    make
    poNumber
    priority
    customerEquipmentNum
    model
    address
    address1
    address2
    city
    state
    zipcode
    country
    lat
    lng
    subject
    scheduledStartDateTime
    scheduledEndDateTime
    isPastDue
    isMissed
    isClosed
    contactEmail
    contactName
    contactPhone
    rescheduleCount
    pastDueCount
    missedCount
    mapURL
    estTravelTime
    estTravelDistance
    estTravelHash
    estTravelFromLatLng
    branchNum
    locationNum
    departmentNum
    workerNames
    projectNum
    taskNum
    workOrderWorkers {
      id
      worker {
        id
        workerNum
        displayName
        user {
          id
          avatarURL
        }
      }
    }
    shipTo {
      id
      name
      customerNum
      address
    }
    billTo {
      id
      name
      customerNum
      address
    }
    equipment {
      id
      equipmentNum
      make
      model
      fleetType
      fleetCode
    }
  }
`;

export const QUERY_GETWORKORDERS = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkOrders(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...WorkOrderFragment
          location {
            id
            locationNum
          }
        }
      }
    }
  }
  ${Fragment_WorkOrder}
`;
