import { gql } from 'apollo-angular';

export const MUTATION_UPDATEEQUIPMENT = gql`
  mutation updateEquipment($data: DtoUpdateEquipment!) {
    updateEquipment(data: $data) {
      id
      issueReported
      issueNotes
    }
  }
`;

// export const MUTATION_ADDCUSTOMER = gql`
//   mutation addCustomer(
//     $name: String!
//     $type: String!
//   ) {
//     addCustomer(
//       data: {
//         name: $name
//         type: $type
//       }
//     ) {
//       id
//     }
//   }
// `;
